<template>
  <v-navigation-drawer app v-model="drawer" style="z-index:60">
    <v-list-item>
      <v-list-item-icon class="cursor-pointer mr-4" @click="$next('/venue/events')">
        <tt-icon width="48" icon="logo" />
      </v-list-item-icon>

      <v-list-item-title class="cursor-pointer primary--text text-bold " @click="$next('/venue/events')">
        TICKET TEXT
      </v-list-item-title>

      <v-spacer />

      <v-list-item-icon class="ma-auto hidden-lg-and-up" @click="$root.$emit('promoter-drawer')">
        <v-icon color="primary">mdi-close</v-icon>
      </v-list-item-icon>
    </v-list-item>

    <v-divider></v-divider>

    <v-list nav>
      <template v-for="item in items">
        <v-list-item
          class="v-list-item-icon-center"
          active-class="primary "
          :dense="!item.divider"
          :key="item.title"
          link
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon color="primary">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="mb-2" v-if="item.divider" :key="item.title + 'divider'"></v-divider>
      </template>
    </v-list>

    <template v-slot:append>
      <v-list-item @click="$logout()">
        <v-list-item-icon>
          <v-icon color="primary">mdi-logout</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      items: [
        // { title: 'Dashboard', icon: 'mdi-home-outline', link: '/promoter/dashboard', divider: true },
        { title: 'Events', icon: 'mdi-calendar', link: '/venue/events' },
        { title: 'Venues', icon: 'mdi-map-marker-outline', link: '/venue/venues' },
        { title: 'Order history', icon: 'mdi-tag-outline', link: '/venue/order-history' },
        { title: 'Account settings', icon: 'mdi-account-circle-outline', link: '/venue/account' }
      ],
      drawer: this.$vuetify.breakpoint.lgAndUp,
    };
  },
  mounted() {
    this.$root.$on('promoter-drawer', () => {
      this.drawer = !this.drawer;
    });
  },
};
</script>
